var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"info"}},[_c('section',{staticClass:"flexView"},[_c('section',{staticClass:"scrollView"},[_vm._m(0),_c('div',{staticClass:"auto-form"},[_c('div',{staticClass:"auto-box"},[_c('form',{staticClass:"auto-inp",attrs:{"action":""}},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('div',{staticClass:"flex",on:{"click":function($event){_vm.showArea = true}}},[_c('label',[_vm._v("地区")]),_vm._m(5)]),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.showArea),callback:function ($$v) {_vm.showArea=$$v},expression:"showArea"}},[_c('van-area',{attrs:{"area-list":_vm.areaList},on:{"confirm":_vm.onConfirm,"cancel":function($event){_vm.showArea = false}}})],1),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_c('div',{staticClass:"flex"},[_c('label',[_vm._v("营业执照")]),_c('div',{staticClass:"flex-box"},[_c('van-uploader',{attrs:{"after-read":_vm.afterRead}})],1)]),_c('button',{staticClass:"apply-btn",attrs:{"onClick":"onSubmit"}},[_vm._v("立即提交")])],1)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"auto-img"},[_c('img',{attrs:{"src":require("../../assets/image/top_bg.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex"},[_c('label',[_vm._v("企业名称")]),_c('div',{staticClass:"flex-box"},[_c('input',{attrs:{"type":"text","placeholder":"请输入企业名称"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex"},[_c('label',[_vm._v("英文名称")]),_c('div',{staticClass:"flex-box"},[_c('input',{attrs:{"type":"text","placeholder":"请输入英文名称"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex"},[_c('label',[_vm._v("许可证号")]),_c('div',{staticClass:"flex-box"},[_c('input',{attrs:{"type":"text","placeholder":"请输入许可证号"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex"},[_c('label',[_vm._v("业务范围")]),_c('div',{staticClass:"flex-box"},[_c('input',{attrs:{"type":"text","placeholder":"请输入业务范围"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-box"},[_c('input',{attrs:{"type":"text","disabled":"","placeholder":"点击选择地区"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex"},[_c('label',[_vm._v("详细地址")]),_c('div',{staticClass:"flex-box"},[_c('input',{attrs:{"type":"text","placeholder":"请输入详细地址"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex"},[_c('label',[_vm._v("发证单位")]),_c('div',{staticClass:"flex-box"},[_c('input',{attrs:{"type":"text","placeholder":"请输入发证单位"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex"},[_c('label',[_vm._v("许可文号")]),_c('div',{staticClass:"flex-box"},[_c('input',{attrs:{"type":"text","placeholder":"请输入许可文号"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex"},[_c('label',[_vm._v("统一信用代码")]),_c('div',{staticClass:"flex-box"},[_c('input',{attrs:{"type":"text","placeholder":"请输入统一信用代码"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex"},[_c('label',[_vm._v("法人代表")]),_c('div',{staticClass:"flex-box"},[_c('input',{attrs:{"type":"text","placeholder":"请输入法人代表"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex"},[_c('label',[_vm._v("联系电话")]),_c('div',{staticClass:"flex-box"},[_c('input',{attrs:{"type":"text","placeholder":"请输入联系电话"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex"},[_c('label',[_vm._v("导游配备数量")]),_c('div',{staticClass:"flex-box"},[_c('input',{attrs:{"type":"text","placeholder":"请输入导游配备数量"}})])])
}]

export { render, staticRenderFns }