<template>
  <div id="info">
    <section class="flexView">
      <section class="scrollView">
        <div class="auto-img">
          <img src="../../assets/image/top_bg.png" alt="" />
        </div>
        <div class="auto-form">
          <div class="auto-box">
            <form action="" class="auto-inp">
              <div class="flex">
                <label>企业名称</label>
                <div class="flex-box">
                  <input type="text" placeholder="请输入企业名称" />
                </div>
              </div>
              <div class="flex">
                <label>英文名称</label>
                <div class="flex-box">
                  <input type="text" placeholder="请输入英文名称" />
                </div>
              </div>
              <div class="flex">
                <label>许可证号</label>
                <div class="flex-box">
                  <input type="text" placeholder="请输入许可证号" />
                </div>
              </div>
              <div class="flex">
                <label>业务范围</label>
                <div class="flex-box">
                  <input type="text" placeholder="请输入业务范围" />
                </div>
              </div>
              <div class="flex" @click="showArea = true">
                <label>地区</label>
                <div class="flex-box">
                  <input type="text" disabled  placeholder="点击选择地区" />
                </div>
              </div>
              <van-popup v-model="showArea" position="bottom">
                <van-area
                  :area-list="areaList"
                  @confirm="onConfirm"
                  @cancel="showArea = false"
                />
              </van-popup>
              <div class="flex">
                <label>详细地址</label>
                <div class="flex-box">
                  <input type="text" placeholder="请输入详细地址" />
                </div>
              </div>
              <div class="flex">
                <label>发证单位</label>
                <div class="flex-box">
                  <input type="text" placeholder="请输入发证单位" />
                </div>
              </div>
              <div class="flex">
                <label>许可文号</label>
                <div class="flex-box">
                  <input type="text" placeholder="请输入许可文号" />
                </div>
              </div>
              <div class="flex">
                <label>统一信用代码</label>
                <div class="flex-box">
                  <input type="text" placeholder="请输入统一信用代码" />
                </div>
              </div>
              <div class="flex">
                <label>法人代表</label>
                <div class="flex-box">
                  <input type="text" placeholder="请输入法人代表" />
                </div>
              </div>
              <div class="flex">
                <label>联系电话</label>
                <div class="flex-box">
                  <input type="text" placeholder="请输入联系电话" />
                </div>
              </div>
              <div class="flex">
                <label>导游配备数量</label>
                <div class="flex-box">
                  <input type="text" placeholder="请输入导游配备数量" />
                </div>
              </div>
               <div class="flex">
                <label>营业执照</label>
                <div class="flex-box">
                  <van-uploader :after-read="afterRead" />
                </div>
              </div>
              <button class="apply-btn" onClick="onSubmit">立即提交</button>
            </form>
          </div>
        </div>
      </section>
    </section>
  </div>
</template>
<script>
export default {
  name: "Infomation",
  components: {},
  data() {
    return {
      info: {
        title: "提示",
        info: "请按照要求填写信息，否则可能会审核不过!",
      },
      value: "",
      showArea: false,
      areaList: [
        {
          code: "110000",
          name: "北京市",
        },
        {
          code: "110100",
          name: "北京市",
        },
        {
          code: "110101",
          name: "东城区",
        },
      ], // 数据格式见 Area 组件文档
    };
  },
  methods: {
    onConfirm(values) {
      this.value = values
        .filter((item) => !!item)
        .map((item) => item.name)
        .join("/");
      this.showArea = false;
    },
    onSubmit() {},
  },
};
</script>
<style scoped>
@import "./style.css";

</style>